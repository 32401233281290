<template>
  <nav>
    <ul>
      <li>
        <router-link to="/mes-donnees">Mes Données</router-link>
      </li>
      <li
        class="predictions"
      >
        <router-link to="/mes-predictions">Mes Prédictions</router-link>
      </li>
      <li
        class="allures"
      >
        <router-link to="/mes-allures">Mes Allures</router-link>
      </li>
      <li>
        <router-link to="/estimer-ma-vma">Estimer ma VMA</router-link>
      </li>
      <router-link to="/a-propos"
        ><i class="fa-solid fa-circle-info" style="color: #3b8fb7"></i
      ></router-link>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "NavComponent",

  computed: {
    allValuesSet() {
      return this.$store.getters.allValuesSet;
    },
  },
  methods: {
    removeAnimation(element) {
      const elementToRemove = document.querySelector(`.${element}`);
      elementToRemove.classList.remove("animated");
        },
  },
};
</script>

<style scoped lang=scss >
@import "@/assets/scss/variables.scss";
nav i {
  display: inline-block;
}

nav {
  background-color: $navbg;
  padding: 10px 0px 10px 0px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  margin: auto;
  /* ajouter une ombre porté vers le haut*/
  box-shadow: 0 -1px 10px #464545;
}

/* sur mobile on réduit */

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
}

a {
  color: black;
  text-decoration: none;
  font-weight: bold;
  font-size: 1em;
  text-align: center;
}

.info-button {
  position: relative;
  display: inline-block;
}

.info-button i {
  font-size: 1.2em;
}

.info-button:hover::before {
  content: attr(title);
  position: absolute;
  bottom: 20px;
  left: -70px;
  background-color: #333333;
  color: white;
  font-size: 0.8em;
  padding: 5px;
  border-radius: 5px;
  z-index: 1;
  width: 150px;
  text-align: center;
  opacity: 0.9;
  visibility: visible;
}

.info-button:hover::after {
  content: "";
  position: absolute;
  bottom: 5px;
  left: 17px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #333333 transparent;
  z-index: 1;
  opacity: 0.9;
  visibility: visible;
}
/* Animation */
@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.9);
  }
}

.animated {
  animation: pulse 1.1s ease-in-out infinite;
}
</style>
