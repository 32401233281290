import { createStore } from 'vuex'


export default createStore({
  state: {
    age: null,
    vma: null,
    fcmax: null,
    genre: null,
    vo2max: null,
  },
  getters: {
    allValuesSet: state => { return state.age != null && state.vma != null && state.fcmax != null && state.genre != null && state.vo2max != null },
  },
  mutations: {
    storeData(state, { age, vma, fcmax, genre, vo2max }) {
      state.age = age;
      state.vma = vma;
      state.fcmax = fcmax;
      state.genre = genre;
      state.vo2max = vo2max;
    },
    setVMA(state, vma) {
      state.vma = vma;
    },

  },
  actions: {
    storeData({ commit }, { age, vma, fcmax, genre, vo2max }) {
      commit('storeData', { age, vma, fcmax, genre, vo2max });
    },
  },
  modules: {
  }
})
