<template>
    <header>
        <div class="header">
            <router-link to="/">
                <img alt="Allure Marathon logo" src="../assets/logo.png">
            </router-link>
            <div class="burgerMenu">
                <BurgerMenuComponent />
            </div>
    </div>
    </header>
</template>

<script>
import BurgerMenuComponent from "@/components/BurgerMenuComponent.vue";
export default {
    name: "HeaderComponent",
    components: {
        BurgerMenuComponent,
    },
    }

</script>

<style lang= 'scss' scoped>
@import '@/assets/scss/variables.scss'; 



.header-logo-style{
    display: flex;
    margin-left: 2em;
}
.user-data{
    margin-right: 2em;
}
.user-name{
    font-size: 2em;
}
header{
    position: fixed;
    z-index: 1000;
    top: 0;
    display: flex;
    justify-content: center; 
    width: 100%; 
    align-items: center; 
    box-shadow: 0 1px 10px #464545;
    background-color: $backgroundMain;
    font-family: 'Montserrat', sans-serif;

margin: auto;
    
}

img{
    height: 100px   ;
}

span {
        display: block;
        font-weight: bold;
        font-size: 0.6em;
    }
.username{
            font-size: 0.8em;
}
h1{
        font-size: 1.6em;
        font-weight: bold;
    }
.deconnection-button{
cursor: pointer;

}
    .burgerMenu{
        display: block;
    }

/* media queries */
/* sur mobil on affiche le Menuburger */
@media screen and (min-width: 640px) {

    .burgerMenu{
        display: none;
    }
}


    

</style>