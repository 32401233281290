import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: 'mes-donnees',
  },
  {
    path: '/estimer-ma-vma',
    name: 'MaVma',
    component: () => import('../views/MaVmaView.vue')
  },
  {
    path: '/mes-donnees',
    name: 'MesDonnees',
    component: () => import('../views/MesDonneesView.vue')
  },
  {
    path: '/mes-predictions',
    name: 'MesPredictions',
    component: () => import('../views/MesPredictionsView.vue')
  },
  {
    path: '/mes-allures',
    name: 'MesAllures',
    component: () => import('../views/MesAlluresView.vue')
  },
  { path: '/a-propos',
   name: 'APropos',
   component: () => import('../views/AProposView.vue') 
  },


]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  next()
})

export default router
