<template>
    <HeaderComponent />
    <div id="view">
     <router-view />
    </div>
    <div class="classicNav">
      <NavComponent />
    </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import NavComponent from "@/components/NavComponent.vue";

export default {
  name: "App",
  components: {
    HeaderComponent,
    NavComponent,
  },
  data() {
    return {
      tab: null,
    };
  },
  methods: {
    changeTab(tab) {
      this.tab = tab;
    },
  },
};
</script>


<style lang="scss">
// @import "reset-css";
@import "@/assets/scss/variables.scss";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");
html,
body {
  margin: auto;
  padding: auto;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  
}

button {
      background-color: $navbg;
      border: none;
      color: $whiteTextColor;
      font-size: 0.9rem;
      font-weight: bold;
      padding: 0.5em 1em;
      border-radius: 4px;
      margin-right: 0.5em;
      cursor: pointer;

      &:hover {
        background-color: lighten($navbg, 5%);
      }
}


#view {
  background-color: white;
  margin: auto;
  padding-top: 100px;
  text-align: center;
  height: 100vh; 
}
@media (min-width: 640px) {
  /* Pour les écrans de largeur minimale de 640px */
  #view {
    width: 640px;
    
  }
  p{
    font-size: 1em;
  }
}

@media (max-width: 639px) {
  /* Pour les écrans de largeur maximale de 639px */
  #view {
    width: 365px;
      p, th,td{
    font-size: 0.8rem;
  }
  h2,h3 {
    font-size: 1.2rem;
  }

  }

}


a {
  color: $links;
  text-decoration: none;

  &.router-link-active {
    color: rgb(255, 255, 255);
    border-radius: 2px;
  }
}
@media screen and (max-width: 640px) {
 .classicNav {
    display: none;
  }
}

</style>
