import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from 'vuetify' // Import de Vuetify
import 'vuetify/dist/vuetify.min.css'
import ScrollPicker from 'vue3-scroll-picker'




createApp(App).use(ScrollPicker).use(store).use(router).use(vuetify).mount('#app')