<template>
  <nav>
    <ul v-show="menuOpen" @click="toggleMenu">
      <li>
        <router-link to="/mes-donnees">Mes Données</router-link>
      </li>
      <li class="predictions">
        <router-link to="/mes-predictions">Mes Prédictions</router-link>
      </li>
      <li class="allures">
        <router-link to="/mes-allures">Mes Allures</router-link>
      </li>
      <li>
        <router-link to="/estimer-ma-vma">Estimer ma VMA</router-link>
      </li>
      <router-link to="/a-propos"
        ><i class="fa-solid fa-circle-info" style="color: #3b8fb7"></i
      ></router-link>
    </ul>
    <button
      class="burger-menu"
      :class="{ animated: allValuesSet }"
      @click="toggleMenu"
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
  </nav>
</template>
<script>
export default {
  name: "BurgerMenuComponent",
  data() {
    return {
      menuOpen: false,
    };
  },
  computed: {
    allValuesSet() {
      return this.$store.getters.allValuesSet;
    },
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
      // supprimer la classe animated du burger menu
      const el = document.querySelector(`button.burger-menu`);
      el.classList.remove("animated");
    },
  },
};
</script>


<style scoped lang=scss>
@import "@/assets/scss/variables.scss";
nav {
  background-color: $navbg;
  padding: 10px 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

li {
  margin-bottom: 10px;
}

a {
  color: black;
  text-decoration: none;
  font-weight: bold;
  font-size: 1em;
  text-align: center;
}

.burger-menu {
  display: block;
  position: absolute;
  top: 30px;
  right: 20px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  padding: 0;
  border: none;
  background-color: transparent;
  z-index: 2;
}

.burger-menu span {
  display: block;
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: black;
  transition: transform 0.3s ease;
}

.burger-menu span:nth-child(1) {
  top: 0;
  border-radius: 2px;
}

.burger-menu span:nth-child(2) {
  top: 14px;
  border-radius: 2px;
}
.burger-menu span:nth-child(3) {
  top: 28px;
  border-radius: 2px;
}

.burger-menu span:nth-child(3) {
  transform-origin: bottom;
}

.burger-menu.open span:nth-child(1) {
  transform: translateY(14px) rotate(45deg);
}

.burger-menu.open span:nth-child(2) {
  transform: translateY(-14px) rotate(-45deg);
}
.burger-menu.open span:nth-child(3) {
  transform: translateY(-14px) rotate(45deg);
}
.burger-menu.open + ul {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
/* Animation */
@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(0.9);
  }
}

.animated {
  animation: pulse 1.1s ease-in-out infinite;
}
</style>